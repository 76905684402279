import React, { useState } from 'react';
import Modal from 'react-modal';
import parse from 'html-react-parser';

import { addParticipant } from '../../utils/functions';
import { signUp, validationErrors } from '../../assets/strings/strings';

import './SignupPage.css';

import logo from '../../assets/logo.svg';
import { CgClose } from 'react-icons/cg';

const customStyles = {
  content: {
    width: '55%',
    height: 'fit-content',
    WebkitOverflowScrolling: 'touch',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    outline: 'none',
    padding: '20px',
    border: '0',
    minHeight: '10rem',
    left: '50%',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '300px',
    maxHeight: '90vh'
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1000
  }
};

const EMAIL_REGEX_EXP = new RegExp(
  // eslint-disable-next-line
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const PHONE_NUMBER_REGEX_EXP = new RegExp(
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
);
const YEAR_REGEX_EXP = new RegExp(/^([1-9]|10|11)$/);

const SignupPage = () => {
  // const [checked, setChecked] = useState([]);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [city, setCity] = useState('');
  const [year, setYear] = useState('');
  const [inputFieldsError, setInputFieldsError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  // const handleCheck = (event) => {
  //   let updatedList = [...checked];

  //   if (event.target.checked) {
  //     updatedList = [...checked, event.target.value];
  //   } else {
  //     updatedList.splice(checked.indexOf(event.target.value), 1);
  //   }

  //   setChecked(updatedList);
  // };

  /**
   * Check if email matches corresponding regex.
   * @param email
   * @returns {boolean}
   */
  const validateEmail = (email) => EMAIL_REGEX_EXP.test(email);

  /**
   * Check if year matches corresponding regex.
   * @param year
   * @returns {boolean}
   */
  const validateYear = (year) => YEAR_REGEX_EXP.test(year);

  /**
   * Check if phone number matches corresponding regex.
   * @param year
   * @returns {boolean}
   */
  const validatePhoneNumber = (phoneNumber) => PHONE_NUMBER_REGEX_EXP.test(phoneNumber);

  const validateEntries = (name, surname, email, phoneNumber, city, year) => {
    if (email === '' && phoneNumber === '' && name === '' && surname === '' && city === '' && year === '') {
      setInputFieldsError(validationErrors.noInformation);
      return false;
    } else if (name === '') {
      setInputFieldsError(validationErrors.invalidName);
      return false;
    } else if (surname === '') {
      setInputFieldsError(validationErrors.invalidSurname);
      return false;
    } else if (email === '' || !validateEmail(email)) {
      setInputFieldsError(validationErrors.invalidEmail);
      return false;
    } else if (phoneNumber === '' || !validatePhoneNumber(phoneNumber)) {
      setInputFieldsError(validationErrors.invalidPhoneNumber);
      return false;
    } else if (city === '') {
      setInputFieldsError(validationErrors.invalidCity);
      return false;
    } else if (year === '' || !validateYear(year)) {
      setInputFieldsError(validationErrors.invalidYear);
      return false;
    // } else if (checked.length === 0) {
    //   setInputFieldsError(validationErrors.invalidMasterclassesNumber);
    //   return false;
    } else {
      setInputFieldsError('');
      setModalOpen(true);
      return true;
    }
  };

  let price = 3500;
  // if (checked.length === 1 && checked.includes('ROBOTICS')) price = 1000;
  // else if (checked.length === 1 && !checked.includes('ROBOTICS')) price = 800;
  // else if (checked.length === 2 && checked.includes('ROBOTICS')) price = 1800;
  // else if (checked.length === 2 && !checked.includes('ROBOTICS')) price = 1600;
  // else if (checked.length === 3) price = 2000;
  // else if (checked.length === 4 && !checked.includes('ROBOTICS')) price = 2800;
  // else if (checked.length === 4 && checked.includes('ROBOTICS')) price = 3000;
  // else if (checked.length === 5) price = 3200;

  return (
    <div className="signup-screen">
      <Modal
        closeTimeoutMS={200}
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
        className="modal"
      >
        <div className="modal-signup">
          <p className={'signup-modal-text'}>{parse(signUp.paymentModal)}</p>
          <CgClose
            color="gray"
            size={20}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer'
            }}
            onClick={() => setModalOpen(false)}
          />
          <a target="_blank" rel="noreferrer" href="https://events.ucu.org.ua/e/APPS_Summer_Camp_2023">
            <button className="signup-button-proceed">{signUp.proceedToPayment}</button>
          </a>
        </div>
      </Modal>
      <div className="signup-main-content">
        <img className="logo-signup" src={logo} alt="APPS Masterclasses Logo" />
        <h2>{signUp.title}</h2>
        <div className="signup-container">
          <div className="signup-input-fields">
            <input
              className="input-signup"
              type="text"
              placeholder={signUp.name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              className="input-signup"
              type="text"
              placeholder={signUp.surname}
              onChange={(e) => setSurname(e.target.value)}
              required
            />
            <input
              className="input-signup"
              type="email"
              name="email"
              placeholder={signUp.email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              className="input-signup"
              type="tel"
              name="phone"
              placeholder={signUp.phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
            <input
              className="input-signup"
              type="text"
              placeholder={signUp.city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <input
              className="input-signup"
              type="text"
              placeholder={signUp.year}
              onChange={(e) => setYear(e.target.value)}
              required
            />
          </div>
          <div className="signup-choosers">
            {/* <p className="signup-subtitle">{signUp.chooseMasterclasses}</p> */}
            <p></p>
            {/* <div className="signup-choose-mc">
              <div className="checkbox-signup">
                <input type="checkbox" id={1} name={'ML'} value={'ML'} onChange={handleCheck} />
                <label for="ML">Машинне навчання (01-02.08.2022)</label>
              </div>
              <div className="checkbox-signup">
                <input type="checkbox" id={1} name={'TG'} value={'TG'} onChange={handleCheck} />
                <label htmlFor="TG">Telegram-бот (03-04.08.2022)</label>
              </div>
              <div className="checkbox-signup">
                <input type="checkbox" id={1} name={'ROBOTICS'} value={'ROBOTICS'} onChange={handleCheck} />
                <label for="ROBOTICS">Робототехніка (05-06.08.2022)</label>
              </div>
              <div className="checkbox-signup">
                <input type="checkbox" id={1} name={'GAMEDEV'} value={'GAMEDEV'} onChange={handleCheck} />
                <label for="GAMEDEV">Розробка ігор (08-09.08.2022)</label>
              </div>
              <div className="checkbox-signup">
                <input type="checkbox" id={1} name={'ALG'} value={'ALG'} onChange={handleCheck} />
                <label htmlFor="ALG">Алгоритми (10-11.08.2022)</label>
              </div>
            </div> */}
            <div style={{ textAlign: 'right', width: '100%' }}>
              <p className="signup-subtitle">{signUp.toPay}</p>
              <p className="ticket-price" style={{ textAlign: 'right' }}>
                {/* ₴{price} */}
                ₴3500
              </p>
              <p style={{ color: 'red', fontWeight: 'bold', fontSize: '12px' }}>{inputFieldsError}</p>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            validateEntries(name, surname, email, phoneNumber, city, year) &&
              addParticipant(name, surname, email, phoneNumber, city, year, price);
          }}
          className="signup-button-proceed"
        >
          {signUp.proceedToPayment}
        </button>
      </div>
    </div>
  );
};

export default SignupPage;
