export const tickets = [
  {
    title: 'Вартість курсу 💜',
    desc: 'Шість днів кемпу, унікальні проекти - чудовий спосіб відчути себе справжнім студентом УКУ! 💛',
    price: '₴2000',
    link: '/signup',
    available: 30
  }
];

export const signUp = {
  name: 'Імʼя',
  surname: 'Прізвище',
  email: 'Електронна пошта',
  phoneNumber: 'Номер телефону',
  city: 'Населений пункт',
  year: 'Клас',
  title: 'Реєстрація на APPS Summer Camp',
  chooseMasterclasses: 'Оберіть курси',
  toPay: 'До сплати:',
  proceedToPayment: 'Перейти до оплати',
  paymentModal:
    'Щоб оплатити участь у таборі, необхідно придбати квиток на платформі UCUevents.\n' +
    'Перейдіть за посиланням нижче та виберіть опцію <span className="highlight">Придбати за 3500 грн.</span>\n' +
    'Просто здійсніть вхід у систему через поштову скриньку, яку ви вказали при реєстрації, та оберіть послідовно опції <b>Придбати та Оплатити</b>, не зважаючи ні на які цифри, підтвердження тощо. Після цього у вас з’явиться доступ до платіжної системи <b>PayHub</b>, де необхідно ввести свої особисті дані та суму до оплати.\n' +
    'Як тільки ми отримаємо інформацію про успішне проходження платежу, вам прийде лист-підтвердження. Якщо ж протягом 3 робочих днів з моменту оплати підтвердження не прийшло, перевірте папку <b>Спам</b> та напишіть нам на пошту <span className="highlight"> <a href="mailto:appscamp@ucu.edu.ua"> appscamp@ucu.edu.ua </a></span> з темою  <span className="highlight">APPS Summer Camp - підтвердження про оплату</span>.\n' +
    'До зустрічі на <b>APPS Summer Camp 2023!</b> 💜\n'
};

export const contents = {
  masterclasses: 'Лекції',
  tickets: 'Квитки',
  faq: 'FAQ',
  buyTicket: 'Купити квиток',
  findMore: 'Дізнатися більше',
  supporting: 'За підтримки',
  full: 'APPS Summer Camp 2024',
  findUs: 'Шукайте нас тут:',
  title: 'APPS Summer Camp<br /><span style="fontSize: 36px; lineHeight: 5px">від студентів ФПН УКУ з 💜</span>',
  description:
    'APPS Summer Camp 2024 – це сім днів насиченого навчання, цікавих проектів та надихаючих знайомств \n' +
    'для школярів середніх та старших класів.',
  discountText:
    'Цього річ команда Apps Summer Camp запрошує вас провести 6 незабутніх днів в Українському католицькому університеті! Якщо ти зацікавлений у пограмуванні, але не знаєш з чого почати, то тобі точно до нас. Під супроводом студентів ти лише за тиждень створиш командний проект та зможеш презентувати його. Також тебе чекають цікаві призи :)'
    };

export const questions = [
  {
    title: 'У мене немає ноутбука, що робити?',
    answer:
      'Ми розраховуємо на те, що у кожного учасника буде власний ноутбук, тому заохочуємо, для вашої ж зручності, принести ноутбук.\n'+
      'Якщо ж у вас виникла нагальна поблема, то просимо попередньо написати на пошту <span className="highlight">\n' +
      '              <a href="mailto:appscamp@ucu.edu.ua"> appscamp@ucu.edu.ua </a>\n' +
      '            </span> листа із темою <span className="highlight">\n' +
      '            APPS Summer Camp - ноутбук</span>.'
  },
  {
    title: 'Я не зі Львова, чи можу приєднатися до події?',
    answer:
      'На жаль, цього річ ми НЕ надаємо проживання в Колегіумі УКУ, у зв\'язку з великою кількістю подій на території кампусу.\n' +
      'Якщо ж ви з іншого міста і дуже хочете відвідати нашу подію, то можемо запропонувати вам зв\'язатися з \n' +
      '<span className="highlight"><a href="https://guests.ucu.edu.ua/kimnaty-dlya-gostej-ucuinn/">UCU INN</span>'
  },
  {
    title: 'Що мені потрібно знати для участі в таборі?',
    answer:
      'Для проходження курсів, запропонованих на таборі, тобі не потрібні ніякі попередні знання у відповідній сфері. Ми розказуватимемо усе з нуля :)'
  },
  {
    title: 'Я вже знаю базові речі з програмування, чи буде мені цікаво?',
    answer:
      'Школа покриватиме як основи Python (від того, що таке змінна, до методів і класів), так і складніші бібліотеки на Python Adanced. До того ж під час виконання проєкту ти зможеш зануритися в деталі наскільки захочеш. Проте ми заохочуємо добре оцінити свої сили, аби тобі від початку було цікаво.'
  },
  {
    title: 'Яка тривалість табору?',
    answer:
      'Кожен день табору починатимеся о 10:00 та закінчуватиметься о 18:00, крім того ми будемо чекати тебе з 9:30 в <b>холі Центру Шептицького УКУ</b> із чаєм, смаколиками та приємними розмовами'
  },
  {
    title: 'Коли дедлайн реєстрації?',
    answer:
      'Дедлайн реєстрації - 21 липня.'
  },
  {
    title: 'Яка тривалість Apps Summer Camp 2024?',
    answer:
      'Apps Summer Camp триває з 22 до 27 липня. Всі лекції будуть зв\'язані між собою, тому заохочуємо відвідувати кемп протягом всього періоду.'
  },
  {
    title: 'У мене є ще питання!',
    answer:
      'Ми з радістю відповімо на решту твоїх питань! Щоб звʼязатися із нами, пиши нам нам пошту <span className="highlight">\n' +
      '              <a href="mailto:appscamp@ucu.edu.ua"> appscamp@ucu.edu.ua </a>\n' +
      '            </span> з темою листа <span className="highlight">APPS Summer Camp\n' +
      '             - питання</span> або ж на нашу сторінку в Instagram - @apps.camp.ucu'
  }
];


export const masterclasses = [
  {
    color: 'linear-gradient(180.86deg, rgba(0, 0, 0, 0) 7%, rgba(0, 183, 7, 1) 50%)',
    active: false,
    title: 'Python Basics',
    image: require('../photo1.jpg'),
    long_title: 'Python Basics',
    date: '01 - 02 / 08 /2023',
    amount: '30',
    desc: "На кемпі ви опануєте основи Python, навчитесь писати свої функції та дізнаєтесь як створювати об'єкти за допомогою ООП!",
    image_modal:
      'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/modalImages%2FdataVisualizationModalCover.jpg?alt=media&token=396bbf81-2f41-4971-8ec2-773cb74e7e4c',
    requirements: ['ноутбук', 'готовність трішки поворушити мізками 🧠', 'хороший настрій 🤠']
  },
  {
    color: 'linear-gradient(180.86deg, rgba(0, 0, 0, 0) 7%, rgba(235, 212, 0, 1) 50%)',
    active: false,
    image: require('../photo2.jpg'),
    title: 'Python Advanced',
    long_title: 'Python Advanced',
    date: '03 - 04 / 08 /2023',
    amount: '30',
    desc: 'Тут вивчиш багато нових бібліотек Python, та навчишся робити безліч неймовірних речей. Звучить цікаво? Тоді ж не гай часу!',
    image_modal:
      'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/modalImages%2FtelegramBotModalCover.jpg?alt=media&token=28daa592-542d-425b-b544-eb212074a761',
    requirements: ['ноутбук', 'чудовий настрій 🤠', 'бажання вивчати нове']
  },
  {
    color: 'linear-gradient(180.86deg, rgba(0, 0, 0, 0) 7%, rgba(114, 78, 145, 1) 50%)',
    active: false,
    image: require('../photo3.jpg'),
    title: 'Github',
    date: '05 - 06 / 08 /2023',
    amount: '30',
    desc: 'Хочеш навчитися ефективно користуватися GitHub для управління своїми проєктами, спільної роботи та збереження коду? Тут ти отримаєш всі необхідні знання та зможеш створити і розвивати власні проєкти разом із іншими розробниками!',
    image_modal:
      'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/modalImages%2F3dModalCover.jpg?alt=media&token=56d99e1e-d87a-4742-af42-a1fe1dbd1d03',
    requirements: ['ноутбук', 'креативні ідеї для твого майбутнього робота 🤖']
  },
  {
    color: 'linear-gradient(180.86deg, rgba(0, 0, 0, 0) 7%, rgba(69, 31, 85, 1) 50%)',
    active: false,
    image: require('../photo4.jpg'),
    title: 'Machine Learning',
    long_title: 'Машине навчання',
    date: '08 - 09 / 08 /2023',
    amount: '30',
    image_modal:
      'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/modalImages%2FwebModalCover.jpg?alt=media&token=342bd7d4-6a45-43db-9631-ec4f0ab0a464',
    desc: 'Хочеш навчитись витискати з даних ту інформацію, яка зможе перевернути уявлення людей про звичні явища та передбачати майбутнє? У такому разі тобі до нас!',
    desc_long: `Хто з нас не мріяв навчити компʼютер виконувати важкі завдання, які, здавалось, можливі лише у фільмах? 🍿 Система детекції обʼєктів у Теслі, прогнозування трендів на фондовому ринку 📈, розумний перекладач — це все сфери, які обʼєднані Машинним Навчанням (МН).
    Mи тебе навчимо аналізувати дані і робити передбачення на їх основі, використовувати базові моделі у MH, і покажемо, що ж насправді ховається за нейронними мережами🤖.
    Нас звати Матвій і Ярослав, ми третьокурсники програми Компʼютерні науки, що обожнюють MH і хочуть поділитись знаннями із вами! Чекаємо!`,
    requirements: ['ноутбук', 'хороший настрій']
  },
  {
    color: 'linear-gradient(180.86deg, rgba(0, 0, 0, 0) 7%, rgba(34, 22, 43, 1) 50%)',
    active: false,
    image: require('../photo5.jpg'),
    title: 'Мистецтво хорошої презентації',
    long_title: 'Мистецтво хорошої презентації',
    date: '10 - 11 / 08 /2023',
    amount: '30',
    image_modal:
      'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/modalImages%2F3dModalCover.jpg?alt=media&token=56d99e1e-d87a-4742-af42-a1fe1dbd1d03',
    desc: 'Під час лекції ви зможете дізнатися секрети хорошої презентації і здобути навички, необхідні для подальшого навчання і роботи.',
    desc_long: `Під час лекції ви зможете дізнатися секрети хорошої презентації і здобути навички, необхідні для подальшого навчання і роботи.`,
    requirements: ['ноутбук', 'хороший настрій 😉']
  }
];

export const images = [
  'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/slideshowImages%2F1.jpeg?alt=media&token=7c3ec1ae-26cb-4150-8e8d-1aff5401aeed',
  'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/slideshowImages%2F2.jpeg?alt=media&token=833899b7-4e2b-4740-b5ec-98efca23abe5',
  'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/slideshowImages%2F3.jpeg?alt=media&token=38d5c3a3-6925-4122-9ed2-fe39abc9a080',
  'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/slideshowImages%2F4.jpeg?alt=media&token=77b0e856-1056-4f76-88b2-3523ccc80407',
  'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/slideshowImages%2F5.jpg?alt=media&token=513150c1-5ff0-45c1-89f1-48fd96032a36',
  'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/slideshowImages%2F6.jpeg?alt=media&token=ae3334dd-8957-40bb-a09f-0cf136671ffc',
  'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/slideshowImages%2F8.jpg?alt=media&token=492de70c-21c7-4a2a-8f65-b2050a022367',
  'https://firebasestorage.googleapis.com/v0/b/masterclasses-landing.appspot.com/o/slideshowImages%2F10.JPG?alt=media&token=e14a750d-479b-4360-88c0-fce3e2571486'
];

export const validationErrors = {
  noInformation: 'Уведіть інформацію про себе',
  invalidName: 'Уведіть своє імʼя',
  invalidSurname: 'Уведіть своє прізвище',
  invalidEmail: 'Уведіть коректну електронну пошту',
  invalidPhoneNumber: 'Уведіть коректний номер телефону',
  invalidCity: 'Уведіть місто',
  invalidYear: 'Уведіть клас (1-11)',
  invalidMasterclassesNumber: 'Оберіть хоча б один майстерклас'
};
