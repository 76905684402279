import React from 'react';
import parse from 'html-react-parser';

// import { FiUsers } from 'react-icons/fi';

import './MasterclassItem.css';

const MasterclassItem = (props) => {
  return (
    <div
      className="masterclass-box"
      style={{ pointerEvents: props.active === false ? 'none' : 'auto' }}
      onClick={props.onClick}
    >
      {/* <div
        className="masterclass-completed"
        style={{
          display: props.active ? 'none' : 'flex',
          opacity: 1
        }}
      >
        <p>Подія відбулася</p>
      </div> */}
      <div style={{ opacity: props.active ? 1 : 1 }}>
        <div className="speaker-photo">
          <img src={props.image} className="speaker-image" alt={''} />
        </div>
        <div className="text-info">
          {/* <div className="text-date-amount">
            <p>{props.date}</p>
            <div className="students-amount">
              <FiUsers color="white" />
              <p>{props.amount}</p>
            </div>
          </div> */}
          <h3>{props.title}</h3>
          <p style={{ fontSize: '14px' }}>{parse(props.description)}</p>
        </div>
        <div
          className="gradient"
          style={{
            background: props.color
          }}
        ></div>
      </div>
    </div>
  );
};

export default MasterclassItem;
