import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { fallDown as Menu } from 'react-burger-menu';
import { Slide } from 'react-slideshow-image';
import parse from 'html-react-parser';

import ModalComponent from '../../components/ModalComponent/ModalComponent';
import MasterclassItem from '../../components/MasterclassItem/MasterclassItem';
import FAQItem from '../../components/FAQItem/FAQItem';
import Ticket from '../../components/Ticket/Ticket';
import { contents, tickets, questions, masterclasses, images } from '../../assets/strings/strings';

import './MainPage.css';
import 'react-slideshow-image/dist/styles.css';

// import logo from '../../assets/logo.svg';
import logo from '../../assets/logo-2024.png';
import { VscMenu } from 'react-icons/vsc';
import { FaInstagram } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import { MdOutlineAlternateEmail } from 'react-icons/md';

const MainPage = () => {
  const [imgCached, setImgCached] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const sliderProperties = {
    duration: 2500,
    autoplay: true,
    transitionDuration: 500,
    arrows: false,
    infinite: true,
    easing: 'ease',
    pauseOnHover: false,
    canSwipe: false
  };

  /**
   * Cache images and show loader while doing this
   * @param srcArrays
   * @returns {Promise<void>}
   */
  const cacheImages = async (srcArrays) => {
    const promises = await srcArrays.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);
  };

  useEffect(() => {
    cacheImages(images).then(() =>
      setTimeout(() => {
        setImgCached(true);
      }, 800)
    );
  }, []);

  return imgCached ? (
    <div>
      <ModalComponent
        modalIsOpenProp={modalIsOpen}
        setIsOpenProp={setIsOpen}
        masterclassModalInfo={modalInfo}
      ></ModalComponent>

      <header>
        <div className="ukrainian-flag">
          <div className={'blue'} />
          <div className={'yellow'} />
        </div>
        <a href="/">
          <img src={logo} alt="APPS Masterclasses logo" className="header-logo" />
        </a>
        <div className="navigation-items">
          <a href="#masterclasses" className="navigation-item-secondary">
            {contents.masterclasses}
          </a>

          <a href="#discount" className="navigation-item-secondary">
            {contents.tickets}
          </a>
          <a href="#faq" className="navigation-item-secondary">
            {contents.faq}
          </a>
          <a href="https://ucu-crm.my.site.com/Surveys/ArdiraSurvey/SurveyResponse.app?recordId=a1HP5000002SOSr&logicalId=fa96978b-166a-886e-8aae-0b757be325a8" target="_blank" rel="noopener noreferrer">
            <button className="navigation-item-primary">{contents.buyTicket}</button>
          </a>

        </div>

        <Menu classname="burger" right customBurgerIcon={<VscMenu />}>
          <a href="#masterclasses" className="navigation-item-secondary">
            {contents.masterclasses}
          </a>

          <a href="#discount" className="navigation-item-secondary">
            {contents.tickets}
          </a>
          <a href="#faq" className="navigation-item-secondary">
            {contents.faq}
          </a>
          <a href="https://ucu-crm.my.site.com/Surveys/ArdiraSurvey/SurveyResponse.app?recordId=a1HP5000002SOSr&logicalId=fa96978b-166a-886e-8aae-0b757be325a8" target="_blank" rel="noopener noreferrer">
            <button className="navigation-item-primary">{contents.buyTicket}</button>
          </a>
        </Menu>
        <div className="header" />
      </header>
      <div id="apps">
        <div className="header-container" />
        <div className="apps-container">
          <div className="apps-texts">
            <h1 className="title-master">{parse(contents.title)}</h1>
            <p>{contents.description}</p>
            <a href="#masterclasses">
              <div className="more-btn">{contents.findMore}</div>
            </a>
          </div>
          <div className="trapeze"></div>
          <div className="photo-slider">
            <Slide {...sliderProperties}>
              {images.map((slideImage, index) => (
                <div key={index}>
                  <img className="each-slide" src={slideImage} alt={'APPS Events'} />
                </div>
              ))}
            </Slide>
          </div>
        </div>
      </div>
      <div id="masterclasses">
        <div className="header-container"></div>
        <h2 className="big-title">{contents.masterclasses}</h2>
        <div className="masterclass-items">
          {masterclasses.map((masterclass) => {
            return (
              <MasterclassItem
                image={masterclass.image}
                active={masterclass.active}
                date={masterclass.date}
                title={masterclass.title}
                description={masterclass.desc}
                amount={masterclass.amount}
                color={masterclass.color}
                onClick={() => {
                  setModalInfo(masterclass);
                  setIsOpen(true);
                }}
              />
            );
          })}
        </div>
      </div>
      <div id="discount">
        <div className="header-container" />
        <h2 className="big-title">{contents.tickets}</h2>
        <div className="tickets-container">
          {tickets.map((ticket) => {
            return (
              <Ticket
                title={ticket.title}
                price={ticket.price}
                desc={ticket.desc}
                link={ticket.link}
                available={ticket.available}
              />
            );
          })}
        </div>
        <div className="discount-container">
          <p className="discount-text">{parse(contents.discountText)}</p>
        </div>
      </div>
      <div id="faq">
        <div className="header-container" />
        <h2 className="big-title">{contents.faq}</h2>
        <div className="questions">
          {questions.map((question, index) => {
            return <FAQItem key={index} title={question.title} answer={parse(question.answer)} />;
          })}
        </div>
      </div>
      <footer>
        <div className="footer-body">
          <div className="footer-sponsors">
            <h3 className="footer-with-support">{contents.supporting}</h3>
            <div className="footer-sponsor-logos">
              <img src={require('../../assets/footer-apps-logo.png')} alt="apps-logo" className="apps-logo" />
              <img src={require('../../assets/footer-ucu-logo.png')} alt="ucu-logo" className="ucu-logo" />
            </div>
          </div>
          <div className="footer-info-part">
            <div className="footer-section-buttons">
              <a href="#masterclasses" className="footer-section-button">
                {contents.masterclasses}
              </a>
              <a href="#discount" className="footer-section-button">
                {contents.tickets}
              </a>
              <a href="#faq" className="footer-section-button">
                {contents.faq}
              </a>
            </div>
            <div className="footer-find-us">
              <div className="footer-find-us-title">{contents.findUs}</div>
              <div className="find-us-icons">
                <a href="https://instagram.com/apps.camp.ucu?utm_medium=copy_link" rel="noreferrer" target="_blank">
                  <div className="footer-find-us-circle">
                    <FaInstagram size={20} />
                  </div>
                </a>
                <a href="mailto:appscamp@ucu.edu.ua" rel="noreferrer" target="_blank">
                  <div className="footer-find-us-circle">
                    <MdOutlineAlternateEmail size={20} />
                  </div>
                </a>
                <a href="https://fb.me/e/aGZMc5oTf" rel="noreferrer" target="_blank">
                  <div className="footer-find-us-circle">
                    <FaFacebookF size={20} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="copyright-logo-text">
            <AiOutlineCopyrightCircle />
            <h4 className="copyright-text">{contents.full}</h4>
          </div>
        </div>
      </footer>
    </div>
  ) : (
    <div className="loader">
      <img src={logo} className="logo-loader" alt={'APPS Masterclasses logo'} />
    </div>
  );
};

export default MainPage;
