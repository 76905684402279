import React from 'react';

import './FAQItem.css';

const FAQItem = (props) => {
  return (
    <div className="faq-box">
      <h3 style={{ marginBottom: '10px' }}>{props.title}</h3>
      <p>{props.answer}</p>
    </div>
  );
};

export default FAQItem;
