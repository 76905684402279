import app from '../utils/firebase';
import { getDatabase, ref, set } from 'firebase/database';

export const addParticipant = (name, surname, email, phoneNumber, city, year, price) => {
  const currentDate = new Date().toISOString();
  const db = getDatabase(app);

  // for (let i = 0; i < events.length; i++) {
  //   set(ref(db, `events/summercamp2023/${events[i]}/${name} ${surname}`), {
  //     name: name,
  //     surname: surname,
  //     email: email,
  //     phoneNumber: phoneNumber,
  //     city: city,
  //     year: year,
  //     masterclasses: events,
  //     price: price,
  //     date: currentDate
  //   });
  // }

  set(ref(db, 'participants2023/' + name + ' ' + surname), {
    name: name,
    surname: surname,
    email: email,
    phoneNumber: phoneNumber,
    city: city,
    year: year,
    // masterclasses: events,
    price: price,
    date: currentDate
  });
};
