import React from 'react';
// import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { CgClose } from 'react-icons/cg';

import './ModalComponent.css';

const customStyles = {
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '15px',
    outline: 'none',
    padding: 0,
    border: '0',
    bottom: 'auto',
    minHeight: '10rem',
    left: '50%',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '300px'
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1000
  }
};

const ModalComponent = (props) => {
  const {masterclassModalInfo, modalIsOpenProp, setIsOpenProp} = props;
  const {requirements, image_modal, long_title, desc_long} = masterclassModalInfo

  const requirementsList = requirements?.map((requirement) => <li>{requirement}</li>);
  const description = desc_long?.split('\n')
    .map((paragraph, i, paragraphs) => {
      if (i + 1 === paragraphs.length) return <p key={i + paragraph[0]}>{paragraph}</p>;
      return <React.Fragment key={i + paragraph[0]}><p>{paragraph}</p><br /></React.Fragment>
    })

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={modalIsOpenProp}
      onRequestClose={() => setIsOpenProp(false)}
      style={customStyles}
      className="modal"
    >
      <div className="modal-body">
        <div
          className="image-part"
          style={{
            backgroundImage: `url(${image_modal})`
          }}
        ></div>
        <div className="info-part">
          <div className="info-part-body">
            <h2 className="modal-title">{long_title}</h2>
            <p className="modal-description">{description}</p>
            <CgClose
              color="gray"
              size={20}
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                cursor: 'pointer'
              }}
              onClick={() => setIsOpenProp(false)}
            />
            <div>
              <h4 className="modal-subtitle">Із собою взяти:</h4>
              <ul className="modal-requirements-list">{requirementsList}</ul>
            </div>
            {/* <Link to={'/signup'} target="_blank">
              <button className="modal-cta-button">Купити квиток</button>
            </Link> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
