import React from "react";
// import { Link } from "react-router-dom";
import "./Ticket.css";

const Ticket = (props) => {
  return (
    <div
      className="ticket-box"
      style={{ opacity: props.available > 0 ? 1 : 0.5 }}
    >
      <p className="ticket-title">{props.title}</p>
      <p className="ticket-desc">{props.desc}</p>
      <div className="price-left">
        <p className="ticket-price">{props.price}</p>
      </div>
      <a href="https://ucu-crm.my.site.com/Surveys/ArdiraSurvey/SurveyResponse.app?recordId=a1HP5000002SOSr&logicalId=fa96978b-166a-886e-8aae-0b757be325a8" target="_blank" rel="noopener noreferrer">
        <button
          style={{ pointerEvents: props.available === 0 ? "none" : "auto" }}
          className="ticket-link"
        >
          Купити
        </button>
      </a>
    </div>
  );
};

export default Ticket;
